import requestUtil from "../../../config/requestUtil"
import Vue from 'vue'

console.log();
export function getList(userId, token, type, sex) {
  return new Promise((resolve, reject) => {
    requestUtil.post(Vue.prototype.baseURLApp + '/user/health/data/info/type/detail', {
      userId,
      token,
      platform: '4',
      type,
      sex
    }).then((res) => {
      if (res.code == 1) {
        if (['birthday', 'height', 'weight'].includes(type)) {
          resolve(res.data.value)
        } else {
          let r = res.data.healthMaterialDetailDictGroup
          let codes = r.codes || []
          let list = r.list || []
          resolve({codes: codes, list: list})
        }
      } else {
        reject()
      }
    })
  })
}